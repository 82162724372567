import React from "react";
import { ScrollBoard } from "@jiaminghi/data-view-react";
import { currentMap, devices } from "views/device/device-obs";
import { wgs84ToGcj02 } from "shared/utils";

const AlarmList = (props) => {
  const { data } = props;
  const config = {
    headerBGC: "#443dc5",
    oddRowBGC: "#09184F",
    evenRowBGC: "#070C34",
    align: ["left"],
    rowNum: 5,
    waitTime: 1 * 3600 * 1000,
    header: [],
    data: data.map((v) => [v.description]),
  };

  const onClick = ({ rowIndex }) => {
    const alarm = data[rowIndex];
    const dev = devices.find((d) => d.Device.Id === alarm.device.id);
    if (dev) {
      const loc = dev.MainStatus && dev.MainStatus.Location;
      if (loc && loc.Lng && loc.Lat) {
        const map = currentMap.map;
        if (map) {
          map.setZoomAndCenter(20, wgs84ToGcj02(loc.Lng, loc.Lat));
        }
      }
    }
  };

  return (
    <div>
      {data && (
        <ScrollBoard
          config={config}
          style={{
            width: "21.5vw",
            height: "24vh",
            cursor: "pointer",
          }}
          onClick={onClick}
        />
      )}
    </div>
  );
};

export default AlarmList;
