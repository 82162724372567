import { Device } from "views/device/models";

export class Alarm {
  id;
  createdAt;
  title;
  description;
  status;
  device = Device.create();

  static create() {
    return new Alarm();
  }
}
