import React from "react";
import { ReactComponent as FullScreenIcon } from "./fullscreen.svg";
import { ReactComponent as FullScreenExitIcon } from "./fullscreen-exit.svg";
import { ReactComponent as IntroIcon } from "./intro.svg";

import styled from "styled-components";

const IconSpan = styled.span`
  cursor: pointer;
  height: auto;
`;

const icons = {
  fullscreen: FullScreenIcon,
  "fullscreen-exit": FullScreenExitIcon,
  intro: IntroIcon,
};

const IconSvg = ({ name, size = 20, color = "white", ...rest }) => {
  const Icon = icons[name];

  return <IconSpan>{Icon && <Icon width={size} height={size} fill={color} {...rest} />}</IconSpan>;
};

export default IconSvg;
