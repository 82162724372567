import React, { PureComponent } from "react";
import { LeftPage, LeftTopBox, LeftBottomBox } from "./elements";

import ConstructionHours from "../device/ConstructionHours";
import FuelConsumption from "../device/FuelConsumption";
import AlarmView from "../alarm/Alarm";

class LeftPageView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <LeftPage>
        {/* 顶部图表 */}
        <LeftTopBox>
          <ConstructionHours />
        </LeftTopBox>

        <LeftTopBox>
          <FuelConsumption />
        </LeftTopBox>

        {/* 底部图表 */}
        <LeftBottomBox>
          <AlarmView />
        </LeftBottomBox>
      </LeftPage>
    );
  }
}

export default LeftPageView;
