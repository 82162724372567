import React, { PureComponent } from "react";
import { BorderBox11 } from "@jiaminghi/data-view-react";

import { CenterPage, CenterBottom, CenterContent, ChartContainer } from "./style";
// import Map from "./charts/Map";
import MapContainer from "../device/DeviceMap";

import DeviceStatsView from "../device/DeviceStats";
import DeviceDistributionView from "../device/DeviceDistribution";
// import MapContainerView from "views/maps/MapContainer";
// import DeviceNameLayer from "views/maps/DeviceNameLayer";
// import DeviceIconLayer from "views/maps/DeviceIconLayer";
// import ProjectAreaLayer from "views/maps/ProjectAreaLayer";

class CenterPageView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <CenterPage>
        <CenterContent></CenterContent>

        <CenterBottom>
          <div className="detail-list">
            <BorderBox11 className="detail-list-item" title="设备分布">
              <ChartContainer>
                <DeviceDistributionView />
              </ChartContainer>
            </BorderBox11>

            <BorderBox11 className="detail-list-item" title="设备状态">
              <ChartContainer>
                <DeviceStatsView />
              </ChartContainer>
            </BorderBox11>
          </div>
        </CenterBottom>
      </CenterPage>
    );
  }
}

export default CenterPageView;
