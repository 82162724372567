import { BorderBox13 } from "@jiaminghi/data-view-react";
import { ModuleTitle } from "elements/globalStyledSet";
import { useEffect, useState } from "react";
import { getAlarms } from "./services";

import AlarmList from "./AlarmList";

const AlarmView = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getAlarms().then((data) => {
      setData(data);
    });
  }, []);

  return (
    <BorderBox13 className="left-bottom-borderBox13">
      <div className="left-bottom">
        <ModuleTitle>
          <i className="iconfont">&#xe88e;</i>
          <span>报警信息</span>
        </ModuleTitle>
        {/* 图表 */}
        <AlarmList data={data}></AlarmList>
      </div>
    </BorderBox13>
  );
};

export default AlarmView;
