import styled from "styled-components";
import pageBg from "../assets/pageBg.png";

export const IndexPageStyle = styled.div`
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  margin: 0px;
  padding: 0 0 0 0;
  background: url(${pageBg}) center center no-repeat;
  background-size: cover;
`;

export const IndexHeader = styled.div`
  width: 100%;
  height: 36px;
`;

export const IndexPageContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  height: calc(100% - 36px);

  pointer-events: none;

  .center-page {
    flex: 1;
  }
`;

export const AuthContainer = styled.div`
  position: relative;
  font-size: 16px;
`;

export const PageContainer = styled.div`
  position: relative;
  padding-top: 10px;
  z-index: 99;
  pointer-events: none;
`;

export const BackgroundContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
`;
