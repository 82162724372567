import Icon from "icons/Icon";
import { useState } from "react";
import { ToolbarContainer } from "./elements";

function toggleFullScreen() {
  if (!document.fullscreenElement) {
    document.documentElement.requestFullscreen();
  } else if (document.exitFullscreen) {
    document.exitFullscreen();
  }
}

function isDocumentInFullScreenMode() {
  return document.fullscreenElement !== null;
}

const Toolbar = () => {
  const [isFull, setIsFull] = useState(isDocumentInFullScreenMode());

  const onToggleFull = () => {
    setIsFull(!isFull);
    toggleFullScreen();
  };

  return (
    <ToolbarContainer>
      <a rel="noreferer" target="_blank" href="https://grizzlycapture.oss-cn-hangzhou.aliyuncs.com/helpers/intro.pdf">
        <Icon name="intro" />
      </a>
      <span style={{ marginRight: 10 }}></span>
      <Icon name={isFull ? "fullscreen-exit" : "fullscreen"} onClick={onToggleFull} />
    </ToolbarContainer>
  );
};

export default Toolbar;
