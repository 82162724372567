import styled from "styled-components";

export const ProjectListContainer = styled.div`
  background-color: #09152a;
  opacity: 0.89;
  height: 100%;
`;

export const ProjectBoardContainer = styled.div`
  color: #bcdcff;

  .dv-capsule-chart {
    overflow-y: auto;
    pointer-events: auto;

    .label-column > * {
      height: 30px;
      line-height: 30px;
    }

    .capsule-container > .capsule-item {
      height: 20px;
      > * {
        height: inherit;
      }
    }
  }

  .project-list-item {
    font-size: 16px;
    padding: 5px 0;
    padding-left: 10px;

    .name {
      cursor: pointer;
    }

    .progress {
      height: 12px;
      margin-top: 10px;
      margin-bottom: 15px;
    }
  }
`;
