import { BorderBox12 } from "@jiaminghi/data-view-react";
import { useEffect, useState } from "react";
import { ModuleTitle } from "elements/globalStyledSet";
import TrafficSituation from "./TrafficSituation";
import { getFuels } from "./services";

import { sum } from "shared/utils";

const AlarmView = () => {
  const [data, setData] = useState({ timeList: [], outData: [] });
  const [total, setTotal] = useState();

  useEffect(() => {
    getFuels().then((data) => {
      if (data.length > 5) {
        data = data.slice(data.length - 31);
      }

      const outData = data.map((v) => v.value.FuelConsumption);
      setTotal(sum(outData).toFixed(2));

      setData({
        timeList: data.map((v) => v.date.split("-").splice(1).join("-")),
        outData,
      });
    });
  }, []);

  return (
    <BorderBox12 className="left-top-borderBox12 box-container">
      <div className="left-top">
        <ModuleTitle>
          <i className="iconfont">&#xe78f;</i>
          <span>油耗态势</span>
        </ModuleTitle>
        <div className="title-dis">
          <span>
            本月油耗:
            <span className="title-dis-keyword">{total ? total : ""}升</span>
          </span>
          {/* <span>
            油耗峰值:
            <span className="title-dis-keyword">{data.peakFlow}升</span>
          </span> */}
        </div>
        {/* 图表 */}
        <TrafficSituation trafficSitua={data}></TrafficSituation>
      </div>
    </BorderBox12>
  );
};

export default AlarmView;
