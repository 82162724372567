export class LocationPoint {
  name;
  address;
  lat;
  lng;

  static create() {
    return new LocationPoint();
  }
}
