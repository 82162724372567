import WebsocketHeartbeatJs from "websocket-heartbeat-js";

let websocketHeartbeatJs = null;

export function processEvent(cb) {
  if (typeof cb === "function") setTimeout(cb);
}

export function heartbeat(cb) {

  console.log("websocketHeartbeatJs",websocketHeartbeatJs)
  if (websocketHeartbeatJs) return;


  console.log("connecting ws")

  websocketHeartbeatJs = new WebsocketHeartbeatJs({
    url:
      "wss://api.grizzlychina.com/device/devicestatuses?events=GPS_ONLINE,ONLINE,WORKING&token=" +
      localStorage.getItem("token"),

    // url: "ws://127.0.0.1:30008/device/devicestatuses?events=GPS_ONLINE,ONLINE,WORKING,RUNNING_TIME"
  });
  websocketHeartbeatJs.onopen = function () {
    websocketHeartbeatJs.send("hello server");
  };
  websocketHeartbeatJs.onmessage = function (e) {
    if (e.data.startsWith("{")) {
      let evt = JSON.parse(e.data);
      if (cb) cb(evt);
      //TOOD 新的起点

      // console.log(newDeviceStatus);
    }
  };
  websocketHeartbeatJs.onreconnect = function () {
    // console.log("reconnecting...");
  };
}
