import api from "apis/api";
import { Project } from "./models";

function calcProgress(data) {
  if (data.Status === "need_schedule") {
    return 20;
  }

  if (data.Status === "executing") {
    return 60;
  }

  return 100;
}

function parseProject(data) {
  const p = Project.create();
  p.id = data.Id;
  p.code = data.ProjectNo;
  p.name = data.ProjectName;
  p.progress = calcProgress(data);

  p.location.lat = Number(data.Lat);
  p.location.lng = Number(data.Lng);
  p.location.address = Number(data.Location);

  p.district.name = data.District;
  p.district.id = data.DistrictId;

  return p;
}

export async function getProjects() {
  const r = await api.get("/schedule/projects");

  return (r || []).map(parseProject);
}
