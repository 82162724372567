import styled from "styled-components";
import { TitleColor } from "./color";

//  标题a
export const ModuleTitle = styled.h3`
  padding: 0.125rem 0.125rem;
  color: ${TitleColor};
  font-size: 0.2rem;
  font-weight: bold;

  .iconfont {
    font-size: 0.175rem;
    margin-right: 0.125rem;
    color: #89e5ff;
    font-weight: 400;
  }
`;
