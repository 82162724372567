import React, { PureComponent } from "react";

import { RightPage, RightTopBox, RightCenterBox } from "./elements";
import ProjectListView from "../project/ProjectList";
import Toolbar from "./Toolbar";

class RightPageView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <RightPage>
        <RightTopBox>
          <Toolbar />
        </RightTopBox>

        <RightCenterBox>
          <ProjectListView />
        </RightCenterBox>
      </RightPage>
    );
  }
}

export default RightPageView;
