export const StatusTypes = {
  Static: 0,
  Shipping: 1,
  Working: 2,
  Running: 3,
  OffLine: -2,
  None: Number.NEGATIVE_INFINITY,
};

export const StatusMap = {
  [StatusTypes.Static]: { label: "静止", color: "#591BB7" },
  [StatusTypes.Shipping]: { label: "托运中", color: "#347CAF" },
  [StatusTypes.Working]: { label: "作业中", color: "#e99d42" },
  [StatusTypes.Running]: { label: "运行中", color: "#81b337" },
  [StatusTypes.OffLine]: { label: "离线", color: "#6C6C6C" },
};

// const NoneStatus = {
//   label: "未入网",
//   color: "#6c6c6c",
// };

export const deviceStatsOptions = [
  StatusTypes.Running,
  StatusTypes.Working,
  StatusTypes.Static,
  StatusTypes.Shipping,
  StatusTypes.OffLine,
];

export function getStatuses(devs) {
  const statuses = deviceStatsOptions.map((v) => {
    const value = devs.filter((d) => {
      const s = d.MainStatus;
      const isOnline = s && s.IsOnline;

      let status = StatusTypes.OffLine;
      if (s && d.WorkingStatus && isOnline) {
        status = d.WorkingStatus.Status;
      }

      return status === v;
    }).length;
    const o = StatusMap[v];
    return { name: o.label, value };
  });

  return statuses;
}
