export class Device {
  id;
  name;
  type;

  static create() {
    return new Device();
  }
}

export function getDistributions(devs) {
  const r = {};
  devs.forEach((d) => {
    const loc = d.MainStatus && d.MainStatus.Location;
    if (!loc || !loc.City) return;
    const k = loc.City;

    const v = r[k] || { name: k, value: 0 };
    v.value++;
    r[k] = v;
  });

  return Object.values(r);
}
