import request from "./request";

const api = {
  ...request,
  get: async (url, params) => {
    const r = await request.get(url, { params });
    return r.data;
  },
};

export default api;
