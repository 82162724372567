import React, { PureComponent } from "react";
import ProgressBar from "@ramonak/react-progress-bar";

import { ProjectBoardContainer } from "./elements";
import { currentMap } from "views/device/device-obs";
class UserSituation extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      config: {
        // 单位
        unit: "%",
        sort: false,
        rowNum: 10,
        showValue: true,
        data: [],
      },
    };
  }

  toProject(p) {
    const map = currentMap.map;
    if (map) {
      map.setZoomAndCenter(12.5, p.geo);
    }
  }

  render() {
    const { data } = this.props;

    return (
      <ProjectBoardContainer>
        {data.map((v) => {
          return (
            <div key={v.id} className="project-list-item">
              <div className="name" onClick={() => this.toProject(v)}>
                {v.fullname}
              </div>
              <ProgressBar className="progress" completed={v.progress} height="16px" borderRadius="2px"></ProgressBar>
            </div>
          );
        })}
      </ProjectBoardContainer>
    );
  }
}

export default UserSituation;
