import api from "./api";

export const getDeviceRoutes = (filter) => {
  return api.get("/schedule/schedules", {
    params: filter,
  });
};

export const getTrailerRoutes = (params) => {
  return api.get("/device/trailers");
};
