import { District } from "models/district";
import { LocationPoint } from "shared/location";
import { wgs84ToGcj02 } from "shared/utils";

export class Project {
  id;
  name;
  code;
  status;
  progress;
  location = LocationPoint.create();
  district = District.create();

  static create() {
    return new Project();
  }

  get fullname() {
    return `${this.code} ${this.name}`;
  }

  get geo() {
    return wgs84ToGcj02(this.location.lng, this.location.lat);
  }
}
