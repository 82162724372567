import React from "react";
import { CapsuleChart } from "@jiaminghi/data-view-react";
import { deviceStatuses } from "./device-obs";
import { useState } from "react";
import { useEffect } from "react";
import { observe } from "mobx";

const DeviceStatsView = (props) => {
  const [config, setConfig] = useState({});

  useEffect(() => {
    observe(deviceStatuses, (v) => {
      setConfig({ unit: "（个）", showValue: false, data: v.object });
    });
  }, []);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <CapsuleChart
        config={config}
        style={{
          width: "100%",
          height: "100%",
        }}
      />
    </div>
  );
};

export default DeviceStatsView;
