import styled from "styled-components";

export const RightPage = styled.div`
  width: 22vw;
  height: calc(100vh - 60px);
  padding: 0px 0.2rem;
  pointer-events: auto;
`;

export const ToolbarContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: auto;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    text-decoration: none;
    color: white;
  }
`;

export const RightTopBox = styled.div`
  position: relative;
  height: 25px;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 10px;

  .right-top {
    &-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
    }
    .earth-gif {
      width: 2.75rem;
      height: auto;
      border-radius: 50%;
      overflow: hidden;
    }
  }
`;

export const RightCenterBox = styled.div`
  position: relative;
  height: calc(100% - 80px);
  width: 100%;
  margin-bottom: 30px;

  .project-list {
    height: 100%;
    overflow: auto;
  }
`;

export const RightBottomBox = styled.div`
  position: relative;
  height: 20vh;
  width: 100%;
  .right-bottom-borderBox13 {
    padding: 0.25rem 0.1875rem 0.1875rem;
    .right-bottom {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      background-color: rgba(19, 25, 47, 0.6);
      .feedback-box {
        margin-top: 0.1rem;
        display: flex;
        align-items: center;
        justify-content: space-around;
        &-item {
          display: flex;
          align-items: center;
          flex-direction: column;
          height: 1.75rem;
          .dis-text {
            font-weight: bold;
            margin-top: 0.0625rem;
            color: #b2cfee;
            font-size: 0.2rem;
            background: linear-gradient(to bottom, #fff, #6176f4);
            color: transparent;
            -webkit-background-clip: text;
            background-clip: text;
          }
        }
      }
      .offline-portal-box {
        margin-top: 0.125rem;
      }
    }
  }
`;
