import { getAlarms } from "apis/alarm";
import { getProjects } from "apis/project";
import { getDeviceRoutes, getTrailerRoutes } from "apis/route";
import { observable } from "mobx";
import { filterDevices, getAllDevices, getDevices } from "./services";
import { getStatuses } from "./status";
import { heartbeat, processEvent } from "./hearbeat";
import { getDistributions } from "./models";

export const currentMap = {
  map: null,
  loca: null,
};

export const updatedDevices = observable([]);

export const alarms = observable([]);
export const devices = observable([]);
export const projects = observable([]);
export const deviceRoutes = observable([]);
export const trailerRoutes = observable([]);
export const deviceDistributions = observable([]);
export const deviceStatuses = observable([]);

export async function load() {
  heartbeat((evt) => {
    if (evt.EventType === "CHANGE") {
      processEvent(() => {
        updatedDevices.replace(evt.Data.filter(filterDevices));
      });
    } else if (evt.EventType === "WORKING") {
      processEvent(() => {
        getAllDevices().then((devs) => {
          deviceStatuses.replace(getStatuses(devs));
        });
      });
    }
  });

  getAllDevices().then((devs) => {
    deviceStatuses.replace(getStatuses(devs));
  });

  getDevices({ HasGps: 1 })
    .then((data) => {
      deviceDistributions.replace(getDistributions(data));
      devices.replace(data);
    })
    .then(async () => {
      getAlarms().then((data) => alarms.replace(data));

      await getProjects().then((data) => projects.replace(data));

      getDeviceRoutes().then((data) => deviceRoutes.replace(data));
      getTrailerRoutes().then((data) => trailerRoutes.replace(data));
    });

  setTimeout(() => load, 15 * 60 * 1000);
}
