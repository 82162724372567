import styled from "styled-components";

export const CenterPage = styled.div`
  margin-top: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 56vw;
  pointer-events: none;
`;

export const ChartContainer = styled.div`
  width: 100%;
  height: 260px;
  padding: 55px 15px 2px 15px;

  > * {
    background-color: #09152a;
    opacity: 0.89;
    border-radius: 25px;
  }
`;

export const CenterContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  pointer-events: none;
`;

export const CenterBottom = styled.div`
  display: flex;
  margin-bottom: 0;
  margin-top: 0.25rem;
  width: 100%;
  pointer-events: auto;

  .detail-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    align-content: space-between;
    justify-content: space-between;
    width: 100%;
    height: 270px;

    &-item {
      display: flex;
      align-items: center;
      position: relative;
      height: 100%;
      /* padding: 0 0.125rem; */
      width: 48%;
      /* padding: 55px 10px 5px 10px; */
      box-sizing: border-box;
    }
  }
`;
