import { ModuleTitle } from "elements/globalStyledSet";
import { useEffect, useState } from "react";
import ProjectsBoard from "./ProjectsBoard";
import { getProjects } from "./services";
import { ProjectListContainer } from "./elements";

const ProjectListView = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getProjects().then(setData);
  }, []);

  return (
    <ProjectListContainer>
      <ModuleTitle>
        <i className="iconfont">&#xe7fd;</i>
        <span>项目</span>
      </ModuleTitle>
      <div className="project-list">
        <ProjectsBoard data={data}></ProjectsBoard>
      </div>
    </ProjectListContainer>
  );
};

export default ProjectListView;
