/* eslint-disable no-unused-vars */
import axios from "axios";
import { getRequestHeaders, logout, isLogined } from "xt-qr-auth";

// 创建axios实例
const service = axios.create({
  baseURL: "https://api.grizzlychina.com",
  // baseURL: "http://127.0.0.1:30008",
  // timeout: timeout, // 请求超时时间
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    config.headers = getRequestHeaders();
    if (!isLogined()) {
      return Promise.reject("token error");
    }

    return config;
  },
  (error) => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);
// response 拦截器
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    const { response } = error;
    if (response && response.status === 401) {
      console.info("err", response.message);
      logout();
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);
export default service;
