import { BorderBox12 } from "@jiaminghi/data-view-react";
import { useEffect, useState } from "react";
import { ModuleTitle } from "elements/globalStyledSet";
import TrafficSituation from "./TrafficSituation";
import { getWorkingHours } from "./services";

import { sum } from "shared/utils";

const ConstructionHoursView = () => {
  const [data, setData] = useState({});
  const [total, setTotal] = useState();

  useEffect(() => {
    getWorkingHours().then((data) => {
      if (data.length > 5) {
        data = data.slice(data.length - 31);
      }

      const outData = data.map((v) => v.value.WorkHour);
      setTotal(sum(outData).toFixed(0));

      setData({
        timeList: data.map((v) => v.date.split("-").splice(1).join("-")),
        outData,
      });
    });
  }, []);

  return (
    <BorderBox12 className="left-top-borderBox12 box-container">
      <div className="left-top">
        <ModuleTitle>
          <i className="iconfont">&#xe78f;</i>
          <span>施工时长态势</span>
        </ModuleTitle>
        <div className="title-dis">
          <span>
            本月工时:
            <span className="title-dis-keyword">{total || 0}小时</span>
          </span>
        </div>

        <TrafficSituation trafficSitua={data}></TrafficSituation>
      </div>
    </BorderBox12>
  );
};

export default ConstructionHoursView;
