import { Charts } from "@jiaminghi/data-view-react";
import { observe } from "mobx";
import { useEffect, useState } from "react";

import { deviceDistributions } from "./device-obs";

function toOption(title, data) {
  return {
    series: [
      {
        type: "pie",
        data,
        insideLabel: {
          show: true,
        },
        roseType: true,
      },
    ],
  };
}

const DeviceDistributionView = () => {
  const [data, setData] = useState();

  useEffect(() => {
    observe(deviceDistributions, (v) => {
      setData(v.object);
    });
  }, []);

  return data && <Charts option={toOption("设备分布", data)} />;
};

export default DeviceDistributionView;
