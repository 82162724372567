import api from "apis/api";

export async function getFuels() {
  const r = await api.get(`/device/workstat`);
  return r;
}

export async function getWorkingHours() {
  const r = await api.get(`/device/workstat`);
  return r;
}

export function filterDevices(v) {
  const loc = v.MainStatus.Location;
  return loc && loc.Lat && loc.Lng;
}

export const getDevices = async (filter) => {
  const r = await getAllDevices(filter);

  return r.filter(filterDevices);
};

export const getAllDevices = async (params) => {
  return await api.get(`/device/deviceinfos`, { ...params });
};
