import api from "apis/api";
import { Alarm } from "./models";

function parseAlarm(data) {
  const a = Alarm.create();
  a.id = data.Id;
  a.type = data.AlarmType;
  a.description = data.Msg;
  a.createdAt = data.CreatedAt;
  a.device.id = data.DeviceId;

  return a;
}

export async function getAlarms() {
  const r = await api.get(`/device/alarms`);
  return (r || []).map(parseAlarm);
}
