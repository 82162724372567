import api from "./api";

export const getAlarms = (filter) => {
  return api
    .get("/device/alarms", {
      params: filter,
    })
    .then(function (response) {
      // handle success
      return response;
    });
};
