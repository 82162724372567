// import MapComponent from './MapContainer';
import { Fragment } from "react";
import { Iconstyle } from "./assets/icon/iconfont";
import { Globalstyle } from "./elements/global.js";
import IndexPage from "./views/IndexPage";

function App() {
  return (
    <Fragment>
      <Iconstyle />
      <Globalstyle />
      <IndexPage />
    </Fragment>
  );
}

export default App;
