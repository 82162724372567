import coordtransform from "coordtransform";

export const wgs84ToGcj02 = (lng, lat) => {
  return coordtransform.wgs84togcj02(parseFloat(lng), parseFloat(lat));
};

export function toGeoJsonArray(data, toCoords) {
  const r = new window.Loca.GeoJSONSource({
    data: {
      type: "FeatureCollection",
      features: Array.prototype.map.call(data, (v) => {
        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: toCoords ? toCoords(v) : [],
          },
          properties: v,
        };
      }),
    },
  });

  return r;
}

export function getCacheValue(cache, key, creator) {
  let v = cache[key];
  if (!v) {
    cache[key] = v = creator();
  }

  return v;
}

export function sum(data) {
  let r = Number(0);
  for (let d of data) {
    r += Number(d);
  }
  return r;
}
