import styled from "styled-components";

export const MapContainer = styled.div`
  position: relative;
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 0;

  .toolbar {
    position: absolute;
    right: 15px;
    bottom: 10px;
    width: 24px;
    height: 24px;
    z-index: 99;
    cursor: pointer;
    img {
      width: 24px;
      height: 24px;
    }
  }

  .map-title {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 50px;
    background-color: rgba(27, 25, 27, 0.384);
  }

  h3 {
    position: absolute;
    left: 10px;
    z-index: 2;
    color: white;
  }

  .map-label {
    position: absolute;
    z-index: 2;
    border: 1px solid #00f;
    background-color: #fff;
    white-space: nowrap;
    cursor: default;
    padding: 3px;
    font-size: 12px;
    line-height: 14px;
  }
`;
