/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import AMapLoader from "@amap/amap-jsapi-loader";
import { getCacheValue, toGeoJsonArray, wgs84ToGcj02 } from "../../shared/utils";

import { MapContainer } from "./elements";
import { devices, alarms, currentMap, load, projects, deviceRoutes, trailerRoutes, updatedDevices } from "./device-obs";
import { observe } from "mobx";

class MapComponent extends Component {
  constructor() {
    super();
    this.state = {};
    this.map = {};
    this.loca = {};
    this.devices = {};
    this.projects = {};
    this.deviceRoutes = {};
    this.alarms = {};
  }

  componentDidMount() {

    console.log('MapComponent did loaded..')

    this.loadMap(async () => {

      console.log('loading..')
      load();

      observe(devices, (v) => this.renderDevices(v.object));
      observe(alarms, (v) => this.renderAlarms(v.object));
      observe(projects, (v) => this.renderProjects(v.object));
      observe(deviceRoutes, (v) => this.renderRoutes(v.object));
      observe(trailerRoutes, (v) => this.renderTrailers(v.object));
      observe(updatedDevices, (v) => this.renderDevices(v.object));
    });
  }

  renderRoutes(routes = []) {
    if (routes) {
      for (let i in routes) {
        this.renderDeviceRoute(routes[i]);
      }

      this.renderTrailers(trailerRoutes);
    }
  }

  renderTrailers(TrailerRoutes = []) {
    for (let item of TrailerRoutes) {
      this.renderTrailerRoute(item);
    }
  }

  renderAlarms(alarms) {
    const breath = this.getAlarmLayer();

    const data = Object.values(this.devices).filter(
      (d) => alarms.findIndex((a) => d.data.Device.Id === a.DeviceId) > -1
    );

    breath.setSource(toGeoJsonArray(data, (d) => d.marker.getPosition()));
  }

  getAlarmLayer() {
    if (this.__breath) {
      return this.__breath;
    }

    const b = new window.Loca.ScatterLayer({
      zIndex: 121,
    });

    const source = {
      data: {
        type: "FeatureCollection",
        features: [],
      },
    };

    b.setSource(new window.Loca.GeoJSONSource(source));

    b.setStyle({
      unit: "px",
      size: [100, 100],
      texture: "https://a.amap.com/Loca/static/loca-v2/demos/images/breath_red.png",
      animate: true,
      duration: 1000,
    });

    // this.loca.add(b);

    this.__breath = b;
    return b;
  }

  renderDeviceRoute(v) {
    if (this.deviceRoutes[v.DeviceId]) {
      //TODO 做DIFF运算

      let line = this.devices[v.DeviceId].line;

      if (line) {
        this.map.remove(line);
      }
    }

    let p = this.projects[v.ProjectId];

    if (!p) {
      return;
    }

    let pp = p.label.getPosition();

    let d = this.devices[v.DeviceId];

    if (!d) {
      return;
    }

    let dp = d.marker.getPosition();

    let line = this.genLine(dp, pp, v);

    this.deviceRoutes[v.DeviceId] = {
      route: v,
      line: line,
    };

    this.map.add(line);
  }

  renderTrailerRoute(v) {
    if (this.deviceRoutes[v.TrailerId]) {
      //TODO 做DIFF运算

      let line = this.deviceRoutes[v.TrailerId].line;

      if (line) {
        this.map.remove(line);
      }
    }

    let s = this.devices[v.TrailerId];

    if (!s) {
      return;
    }

    let t = this.devices[v.DeviceId];

    if (!t) {
      return;
    }

    let tp = t.marker.getPosition();

    let sp = s.marker.getPosition();

    let line = this.genTrailerLine(sp, tp, v);

    this.deviceRoutes[v.TrailerId] = {
      route: v,
      line: line,
    };
    this.map.add(line);
  }

  genTrailerLine(s, t, v) {
    let strokeColor = "#28F";

    if (v.Status === "PREPARE") {
      strokeColor = "#6a6a68";
    }

    let line = new window.AMap.Polyline({
      path: [s, t],
      showDir: true,
      strokeColor: strokeColor, //线颜色
      lineCap: "round",

      strokeWeight: 6,
    });

    return line;
  }

  genLine(s, t, v) {
    let strokeColor = "#28F";

    if (v.Status === "PREPARE") {
      strokeColor = "#6a6a68";
    }

    let line = new window.AMap.Polyline({
      path: [s, t],
      showDir: true,
      strokeColor: strokeColor, //线颜色
      // isOutline: true,  //线颜色
      lineCap: "round",
      strokeOpacity: 1,
      strokeWeight: 6,
    });

    return line;
  }

  renderDevices(items) {
    for (let item of items) {
      this.renderDevice(item);
    }

    this.renderAlarms(alarms);
    this.renderRoutes(deviceRoutes);
  }

  renderDevice(item) {
    if (!currentMap.map) return;

    const devCache = getCacheValue(this.devices, item.Device.Id, () => {
      const c = {
        marker: new window.AMap.Marker({ anchor: "center" }),
        data: item,
        labelMarker: new window.AMap.Marker(),
      };

      c.marker.on("click", (e) => {
        currentMap.map.setZoomAndCenter(10, e.lnglat.toArray());
      });

      this.map.add(c.marker);
      this.map.add(c.labelMarker);
      return c;
    });

    const { marker, labelMarker } = devCache;

    let status = "stop";
    if (!item.MainStatus.IsOnline) {
      status = "offline";
    }

    const loc = item.MainStatus.Location;
    const position = wgs84ToGcj02(loc.Lng, loc.Lat);

    let iconUrl = "//image.grizzlychina.com/models/" + item.Device.ModelCode + "/" + status + ".svg";
    const markerContent = '<div class="custom-content-marker"> <img src="' + iconUrl + '" width="60"></div>';
    marker.setContent(markerContent);
    marker.setPosition(position);

    const labelContent = `<div class="map-label" style="top: -42px; left: 10px;">(${item.Device.AreaName})${item.Device.DeviceName}</div>`;
    labelMarker.setContent(labelContent);
    labelMarker.setPosition(position);

    devCache.data = item;
  }

  renderProjects(items) {
    for (let item of items) {
      this.renderProject(item);
    }

    this.renderRoutes(deviceRoutes);
  }

  renderProject(item) {
    if (this.projects[item.ProjectId]) {
      //TODO 做DIFF运算
      this.map.remove(this.projects[item.ProjectId].label);
      this.map.remove(this.projects[item.ProjectId].circle);
    }

    let p = wgs84ToGcj02(item.Lng, item.Lat);

    let ap = new window.AMap.LngLat(p[0], p[1]);

    let circle = new window.AMap.Circle({
      center: ap, // 圆心位置
      radius: 1500, //半径
      strokeColor: "#aaa", //线颜色
      strokeOpacity: 1, //线透明度
      strokeWeight: 3, //线粗细度
      fillColor: "blue", //填充颜色
      fillOpacity: 0.15, //填充透明度
    });

    this.map.add(circle);

    let pName = item.ProjectName;

    let label = new window.AMap.LabelMarker({
      text: {
        direction: "right",
        content: item.District + " [" + item.Id + "] " + pName,
        style: {
          fillColor: "#555",

          fontSize: 12,
          padding: [3, 10],
        },
      },

      position: p,
    });

    this.map.add(label);

    this.projects[item.Id] = {
      data: item,

      label: label,

      circle: circle,
    };
  }

  loadMap(cb) {
    AMapLoader.load({
      key: "f4bde174e88037d21127656880d5ddfc", //需要设置您申请的key
      version: "2.0",
      plugins: ["AMap.ToolBar", "AMap.Driving", "AMap.MoveAnimation"],
      AMapUI: {
        version: "1.1",
        plugins: [],
      },
      Loca: {
        version: "2.0.0",
      },
    })
      .then((AMap) => {

        console.log('amap loaded')
        this.AMap = AMap;

        this.map = new AMap.Map("mapcontainer", {
          // mapStyle: "amap://styles/256c1eb5d3e8482baabb5d3d348fb50f",
          mapStyle: "amap://styles/darkblue",
          center: wgs84ToGcj02(120.3880646, 30.1743926),
          // center: [119.452971, -4.028965],

          showLabel: true,

          zoom: 7.41,
        });


        // this.loca = new window.Loca.Container({
        //   map: this.map,
        // });
        //
        // this.loca.animate.start();
        // currentMap.loca = this.loca;

        currentMap.map = this.map;
        console.log('amap initial success')

        cb();
      })
      .catch((e) => {});
  }

  render() {
    // 1.创建地图容器
    return (
      <MapContainer>
        <div className="toolbar"></div>
        <div id="mapcontainer" className="map" style={{ width: "100%", height: "100%" }} />;
      </MapContainer>
    );
  }
}

export default MapComponent;
