import React, { useState } from "react";
import { isLogined, XtQrAuth } from "xt-qr-auth";
// import { FullScreenContainer } from "@jiaminghi/data-view-react";

import {
  IndexPageStyle,
  IndexPageContent,
  IndexHeader,
  AuthContainer,
  BackgroundContainer,
  PageContainer,
} from "./style";
import TopPage from "../views/topPage";
import LeftPage from "../views/leftPage";
import CenterPage from "../views/centerPage";
import RightPage from "../views/rightPage";
import DeviceMap from "../views/device/DeviceMap";

function IndexPage(props) {
  const [logged, setLogged] = useState(isLogined());

  function onAuthed() {
    setLogged(true);
  }

  return !logged ? (
    <AuthContainer>
      <XtQrAuth
        className="xt-qr-auth"
        url={`${process.env.NODE_ENV === "development" ? "tapi" : "api"}.grizzlychina.com/auth/ws?platform=ccmap`}
        onAuthed={onAuthed}
      />
    </AuthContainer>
  ) : (
    <IndexPageStyle>
      <BackgroundContainer>
        <DeviceMap />
      </BackgroundContainer>

      <PageContainer>
        <IndexHeader>
          <TopPage />
        </IndexHeader>

        <IndexPageContent>
          {/* 左侧内容 */}
          <LeftPage />
          {/* 中间内容 */}
          <CenterPage className="center-page" />
          {/* 右侧内容 */}
          <RightPage />
        </IndexPageContent>
      </PageContainer>
    </IndexPageStyle>
  );
}

export default IndexPage;
